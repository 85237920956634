* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    background-color: #fff;
    overflow: hidden;
}

h1 {
    color: #323330;
}

.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 20px; */

}

.drop-targets {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    /* width: 80%; */
    margin: 20px 0;
}

.box {
    height: 150px;
    width: 150px;
    border: solid 3px #ccc;
    margin-left: -30px;

    /* align items in the box */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}


.drag-over {
    border: dashed 3px red;
}

.item {
    /* height: 25px; */
    width: 125px;
    background-color: #777777;
    display: flex;
    padding: 5px;
    border: 3px solid #123456;
    align-items: center;
    justify-content: center;

}

.hide {
    display: none;
}